
const priceListData = [
    {
        id: 1,
        label: 'Առաջնակի բուժօգնություն',
        childs: [
            {
                label: "Թերապևտիկ կաբինետներ",
                childs: [
                    {
                        label: "Խորհրդատվություն",
                        value: "2500"
                    },
                    {
                        label: "Բուժման նշանակում",
                        value: "3000"
                    },
                    {
                        label: "Տնային կանչ",
                        value: "3000"
                    },
                    {
                        label: "Կրկնակի այց",
                        value: "1000"
                    },
                    {
                        label: "Խորհրդատվություն տեղամասից դուրս այլ թերապևտի մոտ",
                        value: "2500"
                    }
                ]
            },
            {
                label: "Մանկաբուժական կաբինետներ",
                childs: [
                    {
                        label: "Խորհրդատվություն",
                        value: "3000"
                    },
                    {
                        label: "Բուժման նշանակում",
                        value: "3000"
                    },
                    {
                        label: "Տնային կանչ",
                        value: "3500"
                    },
                    {
                        label: "Կրկնակի այց",
                        value: "1000"
                    },
                    {
                        label: "Խորհրդատվություն տեղամասից դուրս այլ մանկաբույժի մոտ",
                        value: "3000"
                    }
                ]
            }
        ]
    },
    {
        id: 2,
        label: 'Նեղ մասնագիտական ծառայություններ',
        childs: [
            {
                label: "Սրտաբանական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "2500"
                    },
                    {
                        "label": "Բուժում նեյրոցիրկուլյատոր դիստոնիա",
                        "value": "3000"
                    },
                    {
                        "label": "Բուժում սրտի իշեմիկ հիվանդություն",
                        "value": "5000"
                    },
                    {
                        "label": "Բուժում զարկերակային հիպերտոնիա",
                        "value": "5000"
                    },
                    {
                        "label": "Բուժում սիրտ-թոքային անբավարարություն",
                        "value": "5000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    },
                    {
                        "label": "Տնային կանչ",
                        "value": "3000"
                    }
                ]
            },
            {
                label: "Վիրաբուժական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "2500"
                    },
                    {
                        "label": "Տնային այց",
                        "value": "4000"
                    },
                    {
                        "label": "Վիրակապ",
                        "value": "1000"
                    },
                    {
                        "label": "Մեծ ոսկրերի կոտրվածքներ",
                        "value": "10000"
                    },
                    {
                        "label": "Փոքր ոսկրերի կոտրվածքներ",
                        "value": "5000"
                    },
                    {
                        "label": "Միզապարկի կատետերիզացիա",
                        "value": "5000"
                    },
                    {
                        "label": "Հոդախախտի ուղղում",
                        "value": "5000"
                    },
                    {
                        "label": "Թարախակույտի/ հեմատոմայի բացում",
                        "value": "10000"
                    },
                    {
                        "label": "Եղունգի հեռացում",
                        "value": "5000"
                    },
                    {
                        "label": "Բարորակ գոյացությունների /լիպոմա, աթերոմա, ֆիբրոմա/ հեռացում",
                        "value": "10000"
                    },
                    {
                        "label": "Ներհոդային ներարկումներ",
                        "value": "5000"
                    },
                    {
                        "label": "Փոքր վերքերի կարում",
                        "value": "5000"
                    },
                    {
                        "label": "Մեծ վերքերի կարում",
                        "value": "10000"
                    }
                ]
            },
            {
                label: "Նյարդաբանական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "4000"
                    },
                    {
                        "label": "Բուժման նշանակում",
                        "value": "3000"
                    },
                    {
                        "label": "Տնային կանչ",
                        "value": "10000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    }
                ]
            },
            {
                label: "Մանկական նյարդաբանական կաբինետ",
                childs: [
                    {
                        "label": "Մանկական նյարդաբանի խորհրդատվություն",
                        "value": "5000"
                    },
                    {
                        "label": "Բուժում և հսկողություն (1 ամսվա ընթ․ մինչև 4 այց)",
                        "value": "7000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "3000"
                    }
                ]
            },
            {
                label: "Ներզատաբանական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "2500"
                    },
                    {
                        "label": "Բուժման նշանակում",
                        "value": "3000"
                    },
                    {
                        "label": "Տնային կանչ",
                        "value": "3000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    }
                ]
            },
            {
                label: "Ակնաբուժական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն, տեսողության կորեկցիա տեղամասի բնակիչների համար",
                        "value": "2500"
                    },
                    {
                        "label": "Խորհրդատվություն, տեսողության կորեկցիա, ակնոցների որոշումով",
                        "value": "4000"
                    },
                    {
                        "label": "Տոնոմետրիա /ներակնային ճնշման չափում/",
                        "value": "2000"
                    },
                    {
                        "label": "Բիոմիկրոսկոպիա",
                        "value": "2000"
                    },
                    {
                        "label": "Օֆթալմոսկոպիա",
                        "value": "3000"
                    },
                    {
                        "label": "Օտար մարմնի հեռացում",
                        "value": "3000"
                    },
                    {
                        "label": "Պարաբուլբար ներարկում",
                        "value": "1500"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    },
                    {
                        "label": "Խորհրդատվություն տեղամասից դուրս բակիչների համար",
                        "value": "3000"
                    }
                ]
            },
            {
                label: "Քիթ-կոկորդ-ականջաբանական կաբինետ",
                childs: [
                    {
                        "label": "Բժշկի զննում, խորհրդատվություն տեղամասի բնակիչների համար",
                        "value": "5000"
                    },
                    {
                        "label": "Աուդիոմետրիա",
                        "value": "5000"
                    },
                    {
                        "label": "Օտար մարմնի հեռացում 1ականջը",
                        "value": "5000"
                    },
                    {
                        "label": "Օտար մարմնի հեռացում 2ականջը",
                        "value": "8000"
                    },
                    {
                        "label": "Ականջի լվացում 1ականջը",
                        "value": "3000"
                    },
                    {
                        "label": "Ականջի լվացում 2ականջը",
                        "value": "5000"
                    },
                    {
                        "label": "Ծծմբային խցանի հեռացում 1ականջը",
                        "value": "3000"
                    },
                    {
                        "label": "Ծծմբային խցանի հեռացում 2ականջը",
                        "value": "5000"
                    },
                    {
                        "label": "Քթի խոռոչի ցնցուղ /1պրոցեդուրա/",
                        "value": "1000"
                    },
                    {
                        "label": "Լսողական ուղիների մշակում /1պրոցեդուրա/",
                        "value": "1000"
                    },
                    {
                        "label": "Քմային նշիկների լակունաների մշակում /1պրոցեդուրա/",
                        "value": "1000"
                    },
                    {
                        "label": "Թմբկաթաղանթի պնևմոմասաժ /1պրոցեդուրա/",
                        "value": "1000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    },
                    {
                        "label": "Խորհրդատվություն տեղամասից դուրս բնակիչների համար",
                        "value": "5000"
                    },
                    {
                        "label": "Փոքր միջամտություն",
                        "value": "5000"
                    },
                    {
                        "label": "Բորբոքային պռոցեդուրայի բուժում 5 օր",
                        "value": "15000"
                    }
                ]
            },
            {
                label: "Մաշկավեներաբանական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "2500"
                    },
                    {
                        "label": "Բուժման նշանակություն",
                        "value": "3000"
                    },
                    {
                        "label": "Տնային կանչ",
                        "value": "3000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    },
                    {
                        "label": "Գորտնուկի հեռացում 1 հատ",
                        "value": "3000"
                    },
                    {
                        "label": "Գորտնուկի հեռացում 2 հատ",
                        "value": "5000"
                    },
                    {
                        "label": "Գորտնուկի հեռացում 3 հատ և ավել",
                        "value": "6000"
                    },
                    {
                        "label": "Պապիլոմայի հեռացում 1 հատ",
                        "value": "2000"
                    },
                    {
                        "label": "Պապիլոմայի հեռացում 2 հատ",
                        "value": "3000"
                    },
                    {
                        "label": "Պապիլոմայի հեռացում 3 հատ և ավել",
                        "value": "4000"
                    },
                    {
                        "label": "Սրածայր կանդելոմայի հեռացում 1 հատ",
                        "value": "4000"
                    },
                    {
                        "label": "Սրածայր կանդելոմայի հեռացում 2 հատ",
                        "value": "6000"
                    },
                    {
                        "label": "Սրածայր կանդելոմայի հեռացում 3 հատ և ավել",
                        "value": "7000"
                    },
                    {
                        "label": "Կոնտագիոզ մոլյուսկների հեռացում 1 հատ",
                        "value": "2000"
                    },
                    {
                        "label": "Կոնտագիոզ մոլյուսկների հեռացում 2 հատ",
                        "value": "3000"
                    },
                    {
                        "label": "Կոնտագիոզ մոլյուսկների հեռացում 3 հատ և ավել",
                        "value": "4000"
                    },
                    {
                        "label": "Քերուկ սնկի հետազոտման համար",
                        "value": "2000"
                    },
                    {
                        "label": "Դեմոդեկոզի հետազոտություն",
                        "value": "2000"
                    }
                ]
            },
            {
                label: "Գաստրոէնթերոլոգիական կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "2000"
                    },
                    {
                        "label": "Բուժում",
                        "value": "3000"
                    },
                    {
                        "label": "Տնային կանչ",
                        "value": "3000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    }
                ]
            },
            {
                label: "Ուռոլոգիական կաբինետ",
                childs: [
                    {
                        "label": "Տնային խորհրդատվություն ուռոլոգի",
                        "value": "15000"
                    },
                    {
                        "label": "Բժկի խորհրդատվություն",
                        "value": "8000"
                    },
                    {
                        "label": "Կրկնակի խորհրդատվություն / 1ամսվա ընթացքում /",
                        "value": "3000"
                    },
                    {
                        "label": "Քսուկի վերցնում",
                        "value": "2500"
                    },
                    {
                        "label": "Շագանակագեղձի բուժական մերսում /1պրոցեդուրա/",
                        "value": "2000"
                    },
                    {
                        "label": "Դեղամիջոցների ներարկում կանանց միզապարկ /առանց դեղորայքի արժեքի/",
                        "value": "4000"
                    },
                    {
                        "label": "Դեղամիջոցների ներարկում տղամարդկանց միզապարկ /առանց դեղորայքի արժեքի/",
                        "value": "5000"
                    },
                    {
                        "label": "Միզապարկի դրենաժի փոխում/առանց դրենաժի արժեքի/",
                        "value": "5000"
                    },
                    {
                        "label": "Սերմնալարի բլոկադա",
                        "value": "6000"
                    },
                    {
                        "label": "Շագանակագեղձի /ապարատային/ տրանսռեկտալ հետազոտություն",
                        "value": "4000"
                    },
                    {
                        "label": "Կատետրի տեղադրում /առանց կատետրի արժեքի/",
                        "value": "5000"
                    },
                    {
                        "label": "Կատետրի հեռացում",
                        "value": "3000"
                    },
                    {
                        "label": "Սուր ուրետրիտի, ցիստիտի,օրխիտի, էպիդիդիմիտի բուժում / 5-6 այց/",
                        "value": "30000"
                    },
                    {
                        "label": "Քրոնիկ պրոստատիտի բուժում / 5-6 այց/",
                        "value": "40000"
                    },
                    {
                        "label": "Շագանակագեղձի քրոնիկ բորբոքումների,ցիստիտների, էրեկտիլ դիսֆունկցիայի բուժում ֆիզիո -թերապևտիկ կոմպլեքսով 1 /տրանսռեկտալ վիբրոմագնիտոթերապիա, վակում - լազերային բուժում/ 1 պրոցեդուրան",
                        "value": "5000"
                    },
                    {
                        "label": "Շագանակագեղձի քրոնիկ բորբոքումների,ցիստիտների, էրեկտիլ դիսֆունկցիայի բուժում ֆիզիո -թերապևտիկ կոմպլեքսով 2 /տրանսռեկտալ վիբրոմագնիտոթերապիա, վակում - լազերային բուժում/ 1 պրոցեդուրան",
                        "value": "6000"
                    },
                    {
                        "label": "Շագանակագեղձի քրոնիկ բորբոքումների,ցիստիտների, էրեկտիլ դիսֆունկցիայի բուժում ֆիզիո -թերապևտիկ կոմպլեքսով 3 /տրանսռեկտալ վիբրոմագնիտոթերապիա, վակում - լազերային բուժում/ 1 պրոցեդուրան",
                        "value": "7000"
                    },
                    {
                        "label": "Շագանակագեղձի քրոնիկ բորբոքումների,ցիստիտների, էրեկտիլ դիսֆունկցիայի բուժում ֆիզիո -թերապևտիկ կոմպլեքսով 4 /տրանսռեկտալ վիբրոմագնիտոթերապիա, վակում - լազերային բուժում/ 1 պրոցեդուրան",
                        "value": "8000"
                    },
                    {
                        "label": "Տնային պայմաններում ուրոլոգի խորհրդատվություն",
                        "value": "8000"
                    },
                    {
                        "label": "Տնային պայմաններում երիկամների,միզապարկի,շագանակագեղձի ապարատային հետազոտություն",
                        "value": "10000"
                    },
                    {
                        "label": "Բժկի խորհրդատվություն մինչև 18 տարեկան երեխաների համար",
                        "value": "2000"
                    }
                ]
            },
            {
                label: "Ինֆեկցիոն կաբինետ",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "2500"
                    },
                    {
                        "label": "Բուժման նշանակություն",
                        "value": "3000"
                    },
                    {
                        "label": "Տնային կանչ",
                        "value": "3000"
                    },
                    {
                        "label": "Կրկնակի այց",
                        "value": "1000"
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        label: 'Ատամնաբուժական ծառայություններ',
        childs: [
            {
                label: "",
                childs: [
                    {
                        "label": "Խորհրդատվություն",
                        "value": "1000"
                    },
                    {
                        "label": "Ատամի հեռացում 1 բարդության",
                        "value": "2000"
                    },
                    {
                        "label": "Ատամի հեռացում 2 բարդության",
                        "value": "3000"
                    },
                    {
                        "label": "Ատամի հեռացում 3 բարդության",
                        "value": "4000"
                    },
                    {
                        "label": "Ատամի հեռացում 4 բարդության",
                        "value": "5000"
                    },
                    {
                        "label": "Կարիեսի բուժում 1",
                        "value": "5000"
                    },
                    {
                        "label": "Կարիեսի բուժում 2",
                        "value": "6000"
                    },
                    {
                        "label": "Կարիեսի բուժում 3",
                        "value": "7000"
                    },
                    {
                        "label": "Պուլպիտի բուժում/մեկ արմատանի/",
                        "value": "5000"
                    },
                    {
                        "label": "Պուլպիտի բուժում/ Rg,  բազմարմատանի/",
                        "value": "6000"
                    },
                    {
                        "label": "Պերիոդոնտիտ, յուրաքանչյուր ավարտված դեպք /մեկ արմատանի ատամի դեպքում/",
                        "value": "7000"
                    },
                    {
                        "label": "Պերիոդոնտիտ, յուրաքանչյուր ավարտված դեպք / Rg,  բազմարմատանի ատամի դեպքում/",
                        "value": "8000"
                    },
                    {
                        "label": "Ատամնաքարերի հեռացում",
                        "value": "5000"
                    },
                    {
                        "label": "Ատամնապսակի փայլեցում",
                        "value": "3000"
                    },
                    {
                        "label": "Անկերային շտիֆտի տեղադրում 1",
                        "value": "1000"
                    },
                    {
                        "label": "Անկերային շտիֆտի տեղադրում 2",
                        "value": "2000"
                    },
                    {
                        "label": "Անկերային շտիֆտի տեղադրում 3",
                        "value": "3000"
                    },
                    {
                        "label": "Լուսային կոսմետիկ պլոմբ 1",
                        "value": "3000"
                    },
                    {
                        "label": "Լուսային կոսմետիկ պլոմբ 2",
                        "value": "4000"
                    },
                    {
                        "label": "Լուսային կոսմետիկ պլոմբ 3",
                        "value": "5000"
                    },
                    {
                        "label": "Լուսային կոսմետիկ պլոմբ 4",
                        "value": "6000"
                    },
                    {
                        "label": "Լուսային կոսմետիկ պլոմբ 5",
                        "value": "7000"
                    },
                    {
                        "label": "Բերանի խոռոչի լորձաթաղանթի հիվանդություն /գինգիվիտ, ստոմատիտ/",
                        "value": "7000"
                    },
                    {
                        "label": "Բերանի խոռոչի փափուկ հյուսվածքների վիրաբուժական միջամտություն /աբսցեսի բացում, ֆլեգմոնա և այլն/",
                        "value": "5000"
                    }
                ]
            },
        ]
    },
    {
        id: 4,
        label: 'Գինեկոլոգիական ծառայություններ*',
        childs: [
            {
                label: "Գինեկոլոգիական ծառայություն 0 - 18 տարեկան աղջիկների և կանանց համար ",
                childs: [{
                    "label": "Զննում և խորհրդատվություն",
                    "value": "3000"
                },
                {
                    "label": "Բուժման նշանակում",
                    "value": "2000"
                },
                {
                    "label": "Կրկնակի խորհրդատվություն",
                    "value": "2000"
                },
                {
                    "label": "Հեշտոցային պրոցեդուրա",
                    "value": "1500"
                }
                ]
            },
            {
                label: 'Գինեկոլոգիական ծառայություն  տարածքից դուրս բնակվող 18 -ից բարձր կանանց համար',
                childs: [{
                    "label": "Զննում և խորհրդատվություն",
                    "value": "4000"
                },
                {
                    "label": "Բուժման նշանակում",
                    "value": "2000"
                },
                {
                    "label": "Կրկնակի խորհրդատվություն",
                    "value": "2000"
                },
                {
                    "label": "Արգանդի  պարանոցի պոլիպի հեռացում",
                    "value": "5000"
                },
                {
                    "label": "Արգանդի պարանոցի ֆոնային հիվանդությունների դիաթերմոկոագուլյացիա",
                    "value": "10000"
                },
                {
                    "label": "Հեշտոցային օղակի տեղադրում",
                    "value": "5000"
                },
                {
                    "label": "Կոլպոսկոպիա",
                    "value": "5000"
                },
                {
                    "label": "Ներարգանդային  պարույրի տեղադրում",
                    "value": "7000"
                },
                {
                    "label": "Ներարգանդային պարույրի  հեռացում",
                    "value": "3000"
                },
                {
                    "label": "Կաթիլային համակարգի միացում",
                    "value": "1500"
                },
                {
                    "label": "Հեշտոցային պրոցեդուրա",
                    "value": "2000"
                },
                {
                    "label": "Միջմկանային ներարկումներ",
                    "value": "500"
                },
                {
                    "label": "Ներերակային ներարկումներ",
                    "value": "1000"
                },
                {
                    "label": "Իրենց բնակության վայրի վարչական տարածքի սահմաններից դուրս բնակվող հղիների, նորմալ ընթացքով հղիության, նախածննդյան հսկողություն",
                    "value": "40000"
                },
                {
                    "label": "Իրենց բնակության վայրի վարչական տարածքի սահմաններից դուրս բնակվող հղիների, նորմալ ընթացքով հղիության, նախածննդյան հսկողություն(վճ.2 անգ)",
                    "value": "20000"
                },
                {
                    "label": "Ներհեշտոցային ներարկում",
                    "value": "5000"
                }
                ]
            }
        ],
        additionalInfo: ['*Գինեկոլոգիական ծառայությունների  մատուցման  սակագները չեն ներառում դեղորայքային   ծախսերը', '**   Այս ծառայությունները վճարովի են նաև տարածքում բնակվող կանանց համար']
    },
    {
        id: 5,
        label: 'Լաբորատոր - գործիքային հետազոտություններ',
        childs: [
            {
                label: "Լաբորատորիա - Կլինիկական",
                childs: [
                    {
                        "label": "Արյան ընդհանուր հետազոտություն",
                        "value": "1500"
                    },
                    {
                        "label": "Արյան ընդհանուր հետազոտություն լեյկոֆորմուլայով",
                        "value": "2000"
                    },
                    {
                        "label": "Մեզի ընդհանուր հետազոտություն",
                        "value": "1000"
                    },
                    {
                        "label": "Այլ կլինիկական հետազոտություն",
                        "value": "1500"
                    },
                    {
                        "label": "Մեզի հետազոտություն լեղապիգմենտների համար",
                        "value": "500"
                    },
                    {
                        "label": "Բակտերիոսկոպիկ հետազոտություն",
                        "value": "2000"
                    },
                    {
                        "label": "Մաշկի հետազոտում սնկային հիվանդության հայտնաբերման համար",
                        "value": "1500"
                    },
                    {
                        "label": "Ռեակցիա MRP",
                        "value": "1000"
                    },
                    {
                        "label": "Արյան կլինիկական անալիզ (ընդլայնված)",
                        "value": "2500"
                    },
                    {
                        "label": "Դեմոդեքս",
                        "value": "2000"
                    },
                    {
                        "label": "Սնկի հետազոտություն",
                        "value": "2000"
                    },
                    {
                        "label": "Ստեպտակոկ Ա էքսպրես",
                        "value": "3000"
                    }
                ]
            },
            {
                label: "Լաբորատորիա - Բիոքիմիական",
                childs: [{
                    "label": "Շաքարի որոշում արյան մեջ",
                    "value": "1000"
                },
                {
                    "label": "Շաքարի որոշում մեզի մեջ",
                    "value": "1000"
                },
                {
                    "label": "Պրոթրոմբին",
                    "value": "1000"
                },
                {
                    "label": "Հեպատիտ B-ի որոշում",
                    "value": "2000"
                },
                {
                    "label": "Հեպատիտ C-ի որոշում",
                    "value": "3000"
                },
                {
                    "label": "PSA (պրոստատ սպեցիֆիկ անտիգեն)",
                    "value": "4000"
                },
                {
                    "label": "C- ռեակտիվ սպիտակուցի որոշում",
                    "value": "1000"
                },
                {
                    "label": "Ընդհանուր սպիտակուցի որոշում",
                    "value": "500"
                },
                {
                    "label": "Խոլեստերին",
                    "value": "1000"
                },
                {
                    "label": "Բիլիռուբին",
                    "value": "1000"
                },
                {
                    "label": "Ռևմատոիդ գործոնի որոշում",
                    "value": "1000"
                },
                {
                    "label": "Միզանյութի որոշումը արյան մեջ",
                    "value": "1000"
                },
                {
                    "label": "Ավստրալիական հակածնի շճաբանություն",
                    "value": "2000"
                },
                {
                    "label": "Կրեատինին",
                    "value": "1000"
                },
                {
                    "label": "Ցիտոլոգիական հետազոտություն",
                    "value": "2000"
                },
                {
                    "label": "Ընդհանուր կալցիում Ca",
                    "value": "1000"
                },
                {
                    "label": "GGT (Գամմագլյուտամիլտրանսֆերազա)",
                    "value": "1000"
                },
                {
                    "label": "Հիմնային ֆոսֆատազա (ALP)",
                    "value": "1000"
                },
                {
                    "label": "Նատրիում Na",
                    "value": "1000"
                },
                {
                    "label": "Տրիգլիցերիդ",
                    "value": "1000"
                },
                {
                    "label": "LDL խոլեստերին",
                    "value": "1000"
                },
                {
                    "label": "HDL խոլեստերին",
                    "value": "1000"
                },
                {
                    "label": "Գլիկոլիզացված հեմոգլոբին",
                    "value": "4000"
                },
                {
                    "label": "AST",
                    "value": "1000"
                },
                {
                    "label": "ALT",
                    "value": "1000"
                },
                {
                    "label": "Նեչիպորենկոյի փորձ",
                    "value": "1000"
                },
                {
                    "label": "Զիմնիցկու փորձ",
                    "value": "3000"
                },
                {
                    "label": "Ադդիս-Կակովսկու փորձ",
                    "value": "1000"
                },
                {
                    "label": "ASLO",
                    "value": "1000"
                },
                {
                    "label": "Միզաթթվի որոշում",
                    "value": "1000"
                },
                {
                    "label": "ՊԱՊ քսուք",
                    "value": "3000"
                },
                {
                    "label": "Կոագուլոգրամմա",
                    "value": "3000"
                }]
            },
            {
                label: "Լաբորատորիա - Միկրոբիոլոգիական, Սերոլոգիական",
                childs: [{
                    "label": "Ուրեոպլազմա Ureap.ur.",
                    "value": "3500"
                },
                {
                    "label": "Խլամիդիա Chl.Tr.IgG.",
                    "value": "3500"
                },
                {
                    "label": "Արյան խմբի և ռեզուս գործոնի որոշում",
                    "value": "2000"
                },
                {
                    "label": "Հակառեզուս հակամարմինների տիտր",
                    "value": "2000"
                }]
            },
            {
                label: "Ռենտգենոգրաֆիկ հետազոտություններ",
                childs: [{
                    "label": "Գանգի ռենտգենոգրաֆիա 2 նկար / 24x30/",
                    "value": "5000"
                },
                {
                    "label": "Քթի հարակից խոռոչների ռենտգենոգրաֆիա 1 նկար / 18x24/",
                    "value": "2500"
                },
                {
                    "label": "Քթի խոռոչների ռենտգենոգրաֆիա",
                    "value": "2500"
                },
                {
                    "label": "Ողնաշարի պարանոցային հատվածի ռենտգենոգրաֆիա 2 նկար / 18x24/",
                    "value": "4000"
                },
                {
                    "label": "Ողնաշարի կրծքային հատվածի ռենտգենոգրաֆիա 2 նկար / 24x30/",
                    "value": "5000"
                },
                {
                    "label": "Ողնաշարի գոտկային հատվածի ռենտգենոգրաֆիա 2 նկար / 24x30/",
                    "value": "5000"
                },
                {
                    "label": "Կրծքավանդակի օրգանների ռենտգենոգրաֆիա /30 x40/ 1 նկար",
                    "value": "4000"
                },
                {
                    "label": "Կրծքավանդակի օրգանների ռենտգենոգրաֆիա /30 x40/ 2 նկար",
                    "value": "6000"
                },
                {
                    "label": "Ուսահոդի ռենտգենոգրաֆիա 1 նկար / 18x24/",
                    "value": "2500"
                },
                {
                    "label": "Ուսագոտու ռենտգենոգրաֆիա 1 նկար / 24x30/",
                    "value": "3000"
                },
                {
                    "label": "Արմնկային հոդի ռենտգենոգրաֆիա 2 նկար / 18x24/",
                    "value": "4000"
                },
                {
                    "label": "Ճաճանչ-դաստակային հոդի ռենտգենոգրաֆիա 2 նկար / 13x18/",
                    "value": "3000"
                },
                {
                    "label": "Դաստակի ռենտգենոգրաֆիա 2 նկար / 13x18/ կամ / 18x24/",
                    "value": "3000"
                },
                {
                    "label": "Կոնք-ազդրային հոդերի ռենտգենոգրաֆիա 1 նկար / 18x24/",
                    "value": "4000"
                },
                {
                    "label": "Ծնկան հոդի ռենտգենոգրաֆիա 2 նկար / 24x30/",
                    "value": "5000"
                },
                {
                    "label": "Սրունք-թաթային հոդի ռենտգենոգրաֆիա 2 նկար / 18x24/",
                    "value": "4000"
                },
                {
                    "label": "Ոտնաթաթի ռենտգենոգրաֆիա 2 նկար / 18x24/",
                    "value": "4000"
                },
                {
                    "label": "Նախաբազկի ռենտգենոգրաֆիա 2 նկար / 18x24/",
                    "value": "4000"
                },
                {
                    "label": "Սրունքոսկրի ռենտգենոգրաֆիա 2 նկար / 24x30/",
                    "value": "5000"
                },
                {
                    "label": "Կրնկոսկրի ռենտգենոգրաֆիա 1 նկար / 13x18/",
                    "value": "2000"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար` 13x18 չափի",
                    "value": "500"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար` 18x24 չափի",
                    "value": "1000"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար` 24x30 չափի",
                    "value": "1500"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար` 30x40 չափի",
                    "value": "2000"
                },
                {
                    "label": "Ատամի ռենտգենոգրաֆիա",
                    "value": "1000"
                },
                {
                    "label": "Ֆլյուորոգրաֆիա 1 նկար",
                    "value": "2500"
                },
                {
                    "label": "Ռենտգենոսկոպիա շնչառական համակարգի",
                    "value": "6000"
                },
                {
                    "label": "Ռենտգենոսկոպիա աղեստամոքսային համակարգի կոնտրաստով",
                    "value": "8000"
                },
                {
                    "label": "Կրծքավանդակի օրգանների ռենտգենոգրաֆիա մանկ. /24x30/ 1 նկար",
                    "value": "2500"
                },
                {
                    "label": "Ֆլյուորոգրաֆիա 1 նկար (ըստ պայմանագրի)",
                    "value": "1000"
                }]
            },
            {
                label: "Ուլտրաձայնային հետազոտություն",
                childs: [{
                    "label": "Որովայնի խոռոչի և փոքր կոնքի օրգանների ՈՒՁՀ",
                    "value": "8000"
                },
                {
                    "label": "Որովայնի խոռոչի օրգանների ՈՒՁՀ (լյարդ, լեղապարկ, ստամոքս, փայծաղ)",
                    "value": "5000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների ՈՒՁՀ",
                    "value": "5000"
                },
                {
                    "label": "Հղիների ՈՒՁՀ",
                    "value": "5000"
                },
                {
                    "label": "ՈՒՁՀ դինամիկայում",
                    "value": "2000"
                },
                {
                    "label": "Վահանաձև գեղձի ՈՒՁՀ",
                    "value": "6000"
                },
                {
                    "label": "Կրծքագեղձի ՈՒՁՀ",
                    "value": "6000"
                },
                {
                    "label": "Լիմֆատիկ գեղձերի ՈՒՁՀ",
                    "value": "3000"
                },
                {
                    "label": "Հոդերի ՈՒՁՀ",
                    "value": "5000"
                },
                {
                    "label": "Էխոսրտագրություն",
                    "value": "10000"
                },
                {
                    "label": "Ամորձիների ՈՒՁՀ",
                    "value": "5000"
                },
                {
                    "label": "Երկու հոդերի ՈւՁՀ",
                    "value": "8000"
                },
                {
                    "label": "Ֆոլիկուլոմետրիա",
                    "value": "2000"
                },
                {
                    "label": "Դուպլեքս հետազոտություն",
                    "value": "9000"
                },
                {
                    "label": "Ներհեշտոցային ՈՒՁՀ",
                    "value": "8000"
                },
                {
                    "label": "Դոպլերֆլյուսիմետրիա",
                    "value": "10000"
                },
                {
                    "label": "Պտղի սեռի որոշում",
                    "value": "5000"
                },
                {
                    "label": "Փափուկ հյուսվածքների ՈՒՁՀ",
                    "value": "5000"
                },
                {
                    "label": "Պտղի դոպլեր հետազոտություն",
                    "value": "12000"
                },
                {
                    "label": "ՈՒՁՀ շարժական",
                    "value": "8000"
                }]
            },
            {
                label: "Այլ հետազոտություններ",
                childs: [{
                    "label": "ԷՍԳ",
                    "value": "2000"
                },
                {
                    "label": "ԷՍԳ շարժական",
                    "value": "2500"
                },
                {
                    "label": "Սպիրոգրաֆիա",
                    "value": "2000"
                },
                {
                    "label": "Սպիրոմետրիա",
                    "value": "2000"
                },
                {
                    "label": "Պուլսօքսիմետրիա",
                    "value": "500"
                },
                {
                    "label": "ԷՆՄԳ 2 վերջույթ /էլեկտրոնեյրոմիոգրաֆիա/",
                    "value": "15000"
                },
                {
                    "label": "ԷՆՄԳ 4 վերջույթ /էլեկտրոնեյրոմիոգրաֆիա/",
                    "value": "25000"
                },
                {
                    "label": "24 ժամյա հոլտեր մոնիթորինգ",
                    "value": "15000"
                },
                {
                    "label": "24 ժամյա արյան ճնշման մոնիթորինգ",
                    "value": "5000"
                }]
            },
            {
                label: "Ակնաբուժական հետազոտություններ- բուժում",
                childs: [{
                    "label": "Ավտոռեֆ",
                    "value": "2000"
                },
                {
                    "label": "Դեզակոմոդիացիոն բուժում",
                    "value": "20000"
                }]
            }
        ]
    },
    {
        id: 6,
        label: 'Covid-19  թեստավորում',
        childs: [
            {
                label: "",
                childs: [{
                    "label": "Covid-19 թեստավորում",
                    "value": "10000"
                },
                {
                    "label": "Covid-19 թեստավորում /կոլեկտիվ/",
                    "value": "8000"
                },
                {
                    "label": "Covid-19 թեստավորում /կոլեկտիվ 15 հոգուց ավել/",
                    "value": "7500"
                }
                ]
            },
        ]
    },
    {
        id: 7,
        label: 'Վերականգնողական ծառայություններ',
        childs: [
            {
                label: "Վերականգնողական/ բուժական պրոցեդուրաներ",
                childs: [
                    {
                        "label": "Ֆիզիոթերապևտիկ պրոցեդուրա տեղամասի բն",
                        "value": "1500"
                    },
                    {
                        "label": "Ֆիզիոթերապևտիկ պրոցեդուրա տեղամասից դուրս բն",
                        "value": "1700"
                    },
                    {
                        "label": "Ֆիզիոթերապևտիկ պրոցեդուրա / Լազերոթերապիա",
                        "value": "2000"
                    },
                    {
                        "label": "Ներերակային լազերային թերապիա",
                        "value": "1500"
                    },
                    {
                        "label": "Հարվածալիքային թերապիա",
                        "value": "20000"
                    },
                    {
                        "label": "Հարվածալիքային ֆիզիոթերապիա",
                        "value": "3000"
                    },
                    {
                        "label": "Ֆիզիոթերապիա",
                        "value": "1500"
                    },
                    {
                        "label": "Ամպլիպուլս թերապիա /էսէմտէ/",
                        "value": "2000"
                    },
                    {
                        "label": "Ֆիզիոթերապևտիկ պրոցեդուրա 4 էլեկտրոդով",
                        "value": "2000"
                    },
                    {
                        "label": "Ֆիզիոթերապևտիկ պրոցեդուրա 4էլեկտրոդով",
                        "value": "2000"
                    }
                ]
            },
            {
                label: "Մերսման կաբինետ",
                childs: [
                    {
                        "label": "Գլխի մերսում",
                        "value": "1500"
                    },
                    {
                        "label": "Պարանոցի հատվածի մերսում",
                        "value": "2000"
                    },
                    {
                        "label": "Կրծքավանդի հատվածի մերսում",
                        "value": "2000"
                    },
                    {
                        "label": "Գոտկայի հատվածի մերսում",
                        "value": "2000"
                    },
                    {
                        "label": "Վերին 1 վերջույթի մերսում",
                        "value": "1500"
                    },
                    {
                        "label": "Ստորին 1 վերջույթի մերսում",
                        "value": "2000"
                    },
                    {
                        "label": "Դեմքի մերսում",
                        "value": "1500"
                    },
                    {
                        "label": "Մանկական մերսում",
                        "value": "1500"
                    }
                ]
            },
        ]
    },
    {
        id: 8,
        label: 'Այլ վճարովի   ծառայություններ',
        childs: [
            {
                label: "",
                childs: [{
                    "label": "Արյան նմուշառում շարժական",
                    "value": "1500"
                },
                {
                    "label": "18 տարին լրացած և ավելի բարձր տարիքի, սոցիալապես անապահով և հատուկ խմբերում չընդգրկված անձանց համար, համաձայն բժշկի նշանակման, տնային պայմաններում կատարվող միջմկանային ներարկումներ",
                    "value": "500"
                },
                {
                    "label": "18 տարին լրացած և ավելի բարձր տարիքի, սոցիալապես անապահով և հատուկ խմբերում չընդգրկված անձանց համար, համաձայն բժշկի նշանակման, տնային պայմաններում կատարվող ներերակային ներարկումներ",
                    "value": "1000"
                },
                {
                    "label": "Հակակատաղության պատվաստում",
                    "value": "9000"
                },
                {
                    "label": "Հակափայտացման պատվաստում",
                    "value": "2000"
                },
                {
                    "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն /առանց բակտերիոլոգիական հետազոտությունների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն*",
                    "value": "5000"
                },
                {
                    "label": "Դեսպանատների կողմից տրվող, արտերկիր մեկնելու համար, անձի առողջական վիճակը հավաստող ձևաթղթի լրացում",
                    "value": "15000"
                },
                {
                    "label": "Քաղվածք և կացության տեղեկանք առողջության վերաբերյալ, տեղամասից դուրս բնակվող, 18-ից բարձր տարիքի անձանց համար",
                    "value": "10000"
                },
                {
                    "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն /առանց բակտերիոլոգիական հետազոտությունների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն (ըստ պայմանագրի) 1",
                    "value": "4000"
                },
                {
                    "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն /առանց բակտերիոլոգիական հետազոտությունների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն (մանկապարտեզների աշխատակիցների)",
                    "value": "2900"
                },
                {
                    "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն/առանց բակտերիոլոգիական հետազոտությունների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն (ըստ պայմանագրի) 2",
                    "value": "2500"
                },
                {
                    "label": "ՀՀ-ում քաղաքացիներին զենքի տիրապետման համար բժշկական եզրակացություն տրամադրելու նպատակով բժշկական զննում",
                    "value": "15000"
                },
                {
                    "label": "Քաղվածք առողջ․ վերաբերյալ, տեղամասի 18-ից բարձր տարիքի անձանց համար",
                    "value": "5000"
                },
                {
                    "label": "Քաղվածք առողջության վերաբերյալ ըստ պայմանագրի",
                    "value": "4000"
                },
                {
                    "label": "Քաղվածք առողջության վերաբերյալ մեծ քանակության դեպքում",
                    "value": "3000"
                },
                {
                    "label": "Բուժզննում ըստ պայմանագրի",
                    "value": "3500"
                },
                {
                    "label": "Վարորդական վկայականի համար տեղեկանք",
                    "value": "15000"
                },
                {
                    "label": "Դատավորի պաշտոնում աշխ․ տեղեկանքի համար բուժզննում",
                    "value": "17000"
                },
                {
                    "label": "ՀՔԾ, ՔԿ ծառ․ աշխ․ տեղեկանքի համար բուժզննում",
                    "value": "15000"
                },
                {
                    "label": "Որդեգրման տեղեկանքի համար բուժզննում",
                    "value": "10000"
                },
                {
                    "label": "Հանրային ծառ․ աշխ․ տեղեկանքի համար բուժզննում",
                    "value": "22000"
                },
                {
                    "label": "Պրոֆ․ քննություն",
                    "value": "4000"
                },
                {
                    "label": "Թերապտի խորհրդատվություն /փրկություն իմնադրամ/",
                    "value": "1000"
                },
                {
                    "label": "Դատախազի պաշտոնում աշխ․ տեղեկանքի համար բուժզննում",
                    "value": "17000"
                },
                {
                    "label": "Դատական կարգադրիչին ներկ․ առողջ․ տեղեկանք",
                    "value": "23000"
                },
                {
                    "label": "Հակակոռուպցիոն կոմիտեի պաշտոնում աշխ․ համար բուժզննում",
                    "value": "25000"
                },
                {
                    "label": "Արագացված տեղեկանքների տրամադրում 30-45ր-ի ընթացքում",
                    "value": "30000"
                },
                {
                    "label": "Քաղվածք Բուժ․ զնն․ Նորագավիթ",
                    "value": "8500"
                },
                {
                    "label": "Ձև 086/ու",
                    "value": "15000"
                },
                {
                    "label": "ԲՓՀ խորհրդատվություն",
                    "value": "5000"
                }
                ]
            },
            {
                label: "Ցերեկային  ստացիոնար",
                childs: [{
                    "label": "Ստացիոնար բուժում /1-10 օր/ յուրաք․ օրը",
                    "value": "5000"
                },
                {
                    "label": "Ստացիոնար բուժում /10 օրից ավել / յուրաք․ օրը",
                    "value": "4000"
                }
                ]
            },
        ]
    },
    {
        id: 9,
        label: 'Լ/Գ փաթեթներ',
        childs: [
            {
                label: "",
                childs: [{
                    "label": "Վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ",
                    "value": "10000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների, վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ",
                    "value": "10000"
                },
                {
                    "label": "Որովայնի խոռոչի  օրգանների, վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ",
                    "value": "13000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների և վահանաձև գեղձի  ՈՒՁՀ",
                    "value": "9000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների և կրծքագեղձի ՈՒՁՀ",
                    "value": "9000"
                },
                {
                    "label": "Որովայնի խոռոչի  օրգանների և վահանաձև գեղձի ՈՒՁՀ",
                    "value": "9000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների ՈՒՁՀ և PSA",
                    "value": "5000"
                },
                {
                    "label": "Վահանաձև գեղձի  ՈՒՁՀ  և խոլեսթերինային ֆրակցիաներ",
                    "value": "8000"
                },
                {
                    "label": "ԷՍԳ և խոլեսթերինային ֆրակցիաներ",
                    "value": "5000"
                },
                {
                    "label": "Արյան մակարդելություն, պրոթրոմբինային ինդեքս,  խոլեսթերինային ֆրակցիաներ",
                    "value": "5000"
                },
                {
                    "label": "Արյան մակարդելություն, պրոթրոմբինային ինդեքս",
                    "value": "1500"
                },
                {
                    "label": "Մեզի ընդհանուր անալիզ, միզաթթվի և կրեատինինի որոշում",
                    "value": "2000"
                },
                {
                    "label": "Բիլիռուբինի ֆրակցիաներ, AST, ALT",
                    "value": "2000"
                },
                {
                    "label": "Հիմնային ֆոսֆատազա, GGT, HBsAg, HCV",
                    "value": "5000"
                },
                {
                    "label": "HBsAg, HCV",
                    "value": "4000"
                },
                {
                    "label": "HBsAg, HCV, AST, ALT",
                    "value": "5500"
                },
                {
                    "label": "Հիմնային ֆոսֆատազա, GGT, AST, ALT",
                    "value": "3000"
                },
                {
                    "label": "Ցիտոլոգիա,  քսուկի անալիզ",
                    "value": "3500"
                },
                {
                    "label": "Քսուկ, ՊԱՊ քսուկ և կոլպոսկոպիա",
                    "value": "7000"
                },
                {
                    "label": "ՊԱՊ քսուկ և կոլպոսկոպիա",
                    "value": "6000"
                },
                {
                    "label": "Ցիտոլոգիա և կոլպոսկոպիա",
                    "value": "5500"
                },
                {
                    "label": "Քսուկ, խլամիդիա, ուրեոպլազմա",
                    "value": "6000"
                },
                {
                    "label": "ԷՍԳ, սպիրոգրաֆիա, պուլսօքսիմետրիա",
                    "value": "3000"
                },
                {
                    "label": "Ներհեշտոցային և փոքր կոնքի օրգանների ՈՒՁՀ",
                    "value": "10000"
                },
                {
                    "label": "Որովայնի, փ/կոնքի, վահանաձև գեղձի կամ որովայնի, փ/կոնքի, կրծքագեղձի ՈւՁՀ",
                    "value": "13000"
                },
                {
                    "label": "Որովայնի, փ/կոնքի, վահանաձև գեղձի, կրծքագեղձի ՈւՁՀ",
                    "value": "18000"
                },
                {
                    "label": "Որովայնի և Կրծքագեղձի ՈւՁՀ",
                    "value": "9000"
                }
                ]
            },
        ]
    },
    {
        id: 10,
        label: 'Պալիատիվ բուժօգնություն',
        childs: [
            {
                label: "",
                childs: [{
                    "label": "Բժշկի առաջնակի զննում տանը",
                    "value": "6500"
                },
                {
                    "label": "Վարման պլանի կազմում",
                    "value": "5000"
                },
                {
                    "label": "Բժշկի կրկնակի այց տանը",
                    "value": "4000"
                },
                {
                    "label": "Բուժքրոջ այց տուն",
                    "value": "2500"
                },
                {
                    "label": "Հոգեբանի այց տուն",
                    "value": "6000"
                },
                {
                    "label": "Սոցաշխատողի այց տուն",
                    "value": "2500"
                },
                {
                    "label": "Ըստ անհրաժեշտության այլ մասնագետի տնային կանչ, խորհրդատվություն",
                    "value": "3500"
                },
                {
                    "label": "Ստեպտակոկ Ա էքսպրես",
                    "value": "3000"
                },
                ]
            },
            {
                label: 'Լաբորատորիա - Բիոքիմիական',
                childs: [{
                    "label": "Շաքարի որոշում արյան մեջ*",
                    "value": "1000"
                },
                {
                    "label": "Շաքարի որոշում մեզի մեջ*",
                    "value": "1000"
                },
                {
                    "label": "Պրոթրոմբին*",
                    "value": "1000"
                },
                {
                    "label": "Հեպատիտ B-ի որոշում*",
                    "value": "2000"
                },
                {
                    "label": "Հեպատիտ C-ի որոշում*",
                    "value": "3000"
                },
                {
                    "label": "PSA (պրոստատ սպեցիֆիկ անտիգեն)",
                    "value": "4000"
                },
                {
                    "label": "C-ռեակտիվ սպիտակուցի որոշում*",
                    "value": "1000"
                },
                {
                    "label": "Ընդհանուր սպիտակուցի որոշում*",
                    "value": "500"
                },
                {
                    "label": "Խոլեստերին*",
                    "value": "1000"
                },
                {
                    "label": "Բիլիռուբին*",
                    "value": "1000"
                },
                {
                    "label": "Ռևմատոիդ գործոնի որոշում*",
                    "value": "1000"
                },
                {
                    "label": "Միզանյութի որոշումը արյան մեջ*",
                    "value": "1000"
                },
                {
                    "label": "Ավստրալիական հակածնի շճաբանություն",
                    "value": "2000"
                },
                {
                    "label": "Կրեատինին*",
                    "value": "1000"
                },
                {
                    "label": "Ցիտոլոգիական հետազոտություն*",
                    "value": "2000"
                },
                {
                    "label": "Ընդհանուր կալցիում Ca",
                    "value": "1000"
                },
                {
                    "label": "GGT (Գամմագլյուտամիլտրանսֆերազա)",
                    "value": "1000"
                },
                {
                    "label": "Հիմնային ֆոսֆատազա (ALP)",
                    "value": "1000"
                },
                {
                    "label": "Նատրիում Na",
                    "value": "1000"
                },
                {
                    "label": "Տրիգլիցերիդ",
                    "value": "1000"
                },
                {
                    "label": "LDL խոլեստերին",
                    "value": "1000"
                },
                {
                    "label": "HDL խոլեստերին",
                    "value": "1000"
                },
                {
                    "label": "Գլիկոլիզացված հեմոգլոբին",
                    "value": "4000"
                },
                {
                    "label": "AST*",
                    "value": "1000"
                },
                {
                    "label": "ALT*",
                    "value": "1000"
                },
                {
                    "label": "Նեչիպորենկոյի փորձ",
                    "value": "1000"
                },
                {
                    "label": "Զիմնիցկու փորձ",
                    "value": "3000"
                },
                {
                    "label": "Ադդիս-Կակովսկու փորձ",
                    "value": "1000"
                },
                {
                    "label": "ASLO",
                    "value": "1000"
                },
                {
                    "label": "Միզաթթվի որոշում",
                    "value": "1000"
                },
                {
                    "label": "ՊԱՊ քսուք",
                    "value": "3000"
                },
                {
                    "label": "Կոագուլոգրամմա",
                    "value": "3000"
                }

                ]
            },
            {
                label: 'Լաբորատորիա - Միկրոբիոլոգիական, Սերոլոգիական',
                childs: [
                    {
                        "label": "Ուրեոպլազմա Ureap.ur.",
                        "value": "3500"
                    },
                    {
                        "label": "Խլամիդիա Chl.Tr.IgG.",
                        "value": "3500"
                    },
                    {
                        "label": "Արյան խմբի և ռեզուս գործոնի որոշում**",
                        "value": "2000"
                    },
                    {
                        "label": "Հակառեզուս հակամարմինների տիտր***",
                        "value": "2000"
                    }
                ]
            },
            {
                label: 'Ռենտգենոգրաֆիկ հետազոտություններ',
                childs: [{
                    "label": "Գանգի ռենտգենոգրաֆիա 2 նկար (24x30)",
                    "value": "5000"
                },
                {
                    "label": "Քթի հարակից խոռոչների ռենտգենոգրաֆիա 1 նկար (18x24)",
                    "value": "2500"
                },
                {
                    "label": "Քթի խոռոչների ռենտգենոգրաֆիա",
                    "value": "2500"
                },
                {
                    "label": "Ողնաշարի պարանոցային հատվածի ռենտգենոգրաֆիա 2 նկար (18x24)",
                    "value": "4000"
                },
                {
                    "label": "Ողնաշարի կրծքային հատվածի ռենտգենոգրաֆիա 2 նկար (24x30)",
                    "value": "5000"
                },
                {
                    "label": "Ողնաշարի գոտկային հատվածի ռենտգենոգրաֆիա 2 նկար (24x30)",
                    "value": "5000"
                },
                {
                    "label": "Կրծքավանդակի օրգանների ռենտգենոգրաֆիա (30x40) 1 նկար",
                    "value": "4000"
                },
                {
                    "label": "Կրծքավանդակի օրգանների ռենտգենոգրաֆիա (30x40) 2 նկար",
                    "value": "6000"
                },
                {
                    "label": "Ուսահոդի ռենտգենոգրաֆիա 1 նկար (18x24)",
                    "value": "2500"
                },
                {
                    "label": "Ուսագոտու ռենտգենոգրաֆիա 1 նկար (24x30)",
                    "value": "3000"
                },
                {
                    "label": "Արմնկային հոդի ռենտգենոգրաֆիա 2 նկար (18x24)",
                    "value": "4000"
                },
                {
                    "label": "Ճաճանչ-դաստակային հոդի ռենտգենոգրաֆիա 2 նկար (13x18)",
                    "value": "3000"
                },
                {
                    "label": "Դաստակի ռենտգենոգրաֆիա 2 նկար (13x18 կամ 18x24)",
                    "value": "3000"
                },
                {
                    "label": "Կոնք-ազդրային հոդերի ռենտգենոգրաֆիա 1 նկար (18x24)",
                    "value": "4000"
                },
                {
                    "label": "Ծնկան հոդի ռենտգենոգրաֆիա 2 նկար (24x30)",
                    "value": "5000"
                },
                {
                    "label": "Սրունք-թաթային հոդի ռենտգենոգրաֆիա 2 նկար (18x24)",
                    "value": "4000"
                },
                {
                    "label": "Ոտնաթաթի ռենտգենոգրաֆիա 2 նկար (18x24)",
                    "value": "4000"
                },
                {
                    "label": "Նախաբազկի ռենտգենոգրաֆիա 2 նկար (18x24)",
                    "value": "4000"
                },
                {
                    "label": "Սրունքոսկրի ռենտգենոգրաֆիա 2 նկար (24x30)",
                    "value": "5000"
                },
                {
                    "label": "Կրնկոսկրի ռենտգենոգրաֆիա 1 նկար (13x18)",
                    "value": "2000"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար (13x18 չափի)",
                    "value": "500"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար (18x24 չափի)",
                    "value": "1000"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար (24x30 չափի)",
                    "value": "1500"
                },
                {
                    "label": "Լրացուցիչ III ռենտգեն նկար (30x40 չափի)",
                    "value": "2000"
                },
                {
                    "label": "Ատամի ռենտգենոգրաֆիա",
                    "value": "1000"
                },
                {
                    "label": "Ֆլյուորոգրաֆիա 1 նկար",
                    "value": "2500"
                },
                {
                    "label": "Ռենտգենոսկոպիա շնչառական համակարգի",
                    "value": "6000"
                },
                {
                    "label": "Ռենտգենոսկոպիա աղեստամոքսային համակարգի կոնտրաստով",
                    "value": "8000"
                },
                {
                    "label": "Կրծքավանդակի օրգանների ռենտգենոգրաֆիա մանկ. (24x30) 1 նկար",
                    "value": "2500"
                },
                {
                    "label": "Ֆլյուորոգրաֆիա 1 նկար (հ menurut պայմանագրի)",
                    "value": "1000"
                }

                ]
            },
            {
                label: 'Ուլտրաձայնային հետազոտություն',
                childs: [
                    {
                        "label": "Որովայնի խոռոչի և փոքր կոնքի օրգանների ՈՒՁՀ",
                        "value": "8000"
                    },
                    {
                        "label": "Որովայնի խոռոչի օրգանների ՈՒՁՀ (լյարդ, լեղապարկ, ստամոքս, փայծաղ)",
                        "value": "5000"
                    },
                    {
                        "label": "Փոքր կոնքի օրգանների ՈՒՁՀ",
                        "value": "5000"
                    },
                    {
                        "label": "Հղիների ՈՒՁՀ",
                        "value": "5000"
                    },
                    {
                        "label": "ՈՒՁՀ դինամիկայում",
                        "value": "2000"
                    },
                    {
                        "label": "Վահանաձև գեղձի ՈՒՁՀ",
                        "value": "6000"
                    },
                    {
                        "label": "Կրծքագեղձի ՈՒՁՀ",
                        "value": "6000"
                    },
                    {
                        "label": "Լիմֆատիկ գեղձերի ՈՒՁՀ",
                        "value": "3000"
                    },
                    {
                        "label": "Հոդերի ՈՒՁՀ",
                        "value": "5000"
                    },
                    {
                        "label": "Էխոսրտագրություն",
                        "value": "10000"
                    },
                    {
                        "label": "Ամորձիների ՈՒՁՀ",
                        "value": "5000"
                    },
                    {
                        "label": "Երկու հոդերիի ՈւՁՀ",
                        "value": "8000"
                    },
                    {
                        "label": "Ֆոլիկուլոմետրիա",
                        "value": "2000"
                    },
                    {
                        "label": "Դուպլեքս հետազոտություն",
                        "value": "9000"
                    },
                    {
                        "label": "Ներհեշտոցային ՈՒՁՀ",
                        "value": "8000"
                    },
                    {
                        "label": "Դոպլերֆլյուսիմետրիա",
                        "value": "10000"
                    },
                    {
                        "label": "Պտղի սեռի որոշում",
                        "value": "5000"
                    },
                    {
                        "label": "Փափուկ հյուսվածքների ՈՒՁՀ",
                        "value": "5000"
                    },
                    {
                        "label": "Պտղի դոպլեր հետազոտություն",
                        "value": "12000"
                    },
                    {
                        "label": "ՈւՁՀ շարժական",
                        "value": "8000"
                    }
                ]
            },
            {
                label: 'Այլ հետազոտություններ',
                childs: [
                    {
                        "label": "ԷՍԳ",
                        "value": "2000"
                    },
                    {
                        "label": "ԷՍԳ շարժական",
                        "value": "2500"
                    },
                    {
                        "label": "Սպիրոգրաֆիա",
                        "value": "2000"
                    },
                    {
                        "label": "Սպիրոմետրիա",
                        "value": "2000"
                    },
                    {
                        "label": "Պուլսօքսիմետրիա",
                        "value": "500"
                    },
                    {
                        "label": "ԷՆՄԳ 2 վերջույթ /էլեկտրոնեյրոմիոգրաֆիա/",
                        "value": "15000"
                    },
                    {
                        "label": "ԷՆՄԳ 4 վերջույթ /էլեկտրոնեյրոմիոգրաֆիա/",
                        "value": "25000"
                    },
                    {
                        "label": "24 ժամյա հոլտեր մոնիթորինգ",
                        "value": "15000"
                    },
                    {
                        "label": "24 ժամյա արյան ճնշման մոնիթորինգ",
                        "value": "5000"
                    }
                ]
            },
            {
                label: "Ակնաբուժական հետազոտություններ- բուժում",
                childs: [{
                    "label": "Ավտոռեֆ",
                    "value": "2000"
                },
                {
                    "label": "Դեզակոմոդիացիոն բուժում",
                    "value": "20000"
                }]
            }
        ]
    },
    {
        id: 11,
        label: 'Covid-19 թեստավորում',
        childs: [
            {
                label: '',
                childs: [{
                    "label": "Covid-19 թեստավորում",
                    "value": "10000"
                },
                {
                    "label": "Covid-19 թեստավորում /կոլեկտիվ/",
                    "value": "8000"
                },
                {
                    "label": "Covid-19 թեստավորում /կոլեկտիվ 15 հոգուց ավել /",
                    "value": "7500"
                }]
            }
        ]
    },
    {
        id: 12,
        label: 'Վերականգնողական ծառայություններ',
        childs: [
            {
                label: "Վերականգնողական/ բուժական պրոցեդուրաներ",
                childs: [{
                    "label": "Ֆիզիոթերապևտիկ պրոցեդուրա տեղամասի բն",
                    "value": "1500"
                },
                {
                    "label": "Ֆիզիոթերապևտիկ պրոցեդուրա տեղամասից դուրս բն",
                    "value": "1700"
                },
                {
                    "label": "Ֆիզիոթերապևտիկ պրոցեդուրա / Լազերոթերապիա",
                    "value": "2000"
                },
                {
                    "label": "Ներերակային լազերային թերապիա",
                    "value": "1500"
                },
                {
                    "label": "Հարվածալիքային թերապիա",
                    "value": "20000"
                },
                {
                    "label": "Հարվածալիքային ֆիզիոթերապիա",
                    "value": "3000"
                },
                {
                    "label": "Ֆիզիոթերապիա",
                    "value": "1500"
                },
                {
                    "label": "Ամպլիպուլս թերապիա /էսէմտէ/",
                    "value": "2000"
                },
                {
                    "label": "Ֆիզիոթերապևտիկ պրոցեդուրա 4 էլեկտրոդով",
                    "value": "2000"
                }
                ]
            },
            {
                label: "Մերսման կաբինետ",
                childs: [{
                    "label": "Գլխի մերսում",
                    "value": "1500"
                },
                {
                    "label": "Պարանոցի հատվածի մերսում",
                    "value": "2000"
                },
                {
                    "label": "Կրծքավանդի հատվածի մերսում",
                    "value": "2000"
                },
                {
                    "label": "Գոտկայի հատվածի մերսում",
                    "value": "2000"
                },
                {
                    "label": "Վերին 1 վերջույթի մերսում",
                    "value": "1500"
                },
                {
                    "label": "Ստորին 1 վերջույթի մերսում",
                    "value": "2000"
                },
                {
                    "label": "Դեմքի մերսում",
                    "value": "1500"
                },
                {
                    "label": "Մանկական մերսում",
                    "value": "1500"
                }
                ]
            },
        ]
    },
    {
        id: 13,
        label: 'Այլ վճարովի ծառայություններ',
        childs: [
            {
                label: "",
                childs: [
                    {
                        "label": "Արյան նմուշառում շարժական",
                        "value": "1500"
                    },
                    {
                        "label": "18 տարին լրացած և ավելի բարձր տարիքի, սոցիալապես անապահով և հատուկ խմբերում չընդգրկված անձանց համար, համաձայն բժշկի նշանակման, տնային պայմաններում կատարվող միջմկանային ներարկումներ",
                        "value": "500"
                    },
                    {
                        "label": "18 տարին լրացած և ավելի բարձր տարիքի, սոցիալապես անապահով և հատուկ խմբերում չընդգրկված անձանց համար, համաձայն բժշկի նշանակման, տնային պայմաններում կատարվող ներերակային ներարկումներ",
                        "value": "1000"
                    },
                    {
                        "label": "Հակակատաղության պատվաստում",
                        "value": "9000"
                    },
                    {
                        "label": "Հակափայտացման պատվաստում",
                        "value": "2000"
                    },
                    {
                        "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն /առանց բակտերիոլոգիական հետազոտությւոնների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն",
                        "value": "5000"
                    },
                    {
                        "label": "Դեսպանատների կողմից տրվող, արտերկիր մեկնելու համար, անձի առողջական վիճակը հավաստող ձևաթղթի լրացում",
                        "value": "15000"
                    },
                    {
                        "label": "Քաղվածք և կացության տեղեկանք առողջության վերաբերյալ, տեղամասից դուրս բնակվող, 18-ից բարձր տարիքի անձանց համար",
                        "value": "10000"
                    },
                    {
                        "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն /առանց բակտերիոլոգիական հետազոտութիւնների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն (ըստ պայմանագրի) 1",
                        "value": "4000"
                    },
                    {
                        "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն /առանց բակտերիոլոգիական հետազոտութիւնների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն (մանկապարտեզների աշխատակիցների)",
                        "value": "2900"
                    },
                    {
                        "label": "Աշխատանքի ընդունվելիս/նախնական/և աշխատանքի ընթացքում /պարբերական/ բժշկական քննություններ` ՀՀ կառավարության 2007թ. հուլիսի 6-ի N823-Ն/առանց բակտերիոլոգիական հետազոտությունների արժեքի / և 2004թ. հուլիսի 15-ի N1089-Ն որոշումների համաձայն (ըստ պայմանագրի) 2",
                        "value": "2500"
                    },
                    {
                        "label": "ՀՀ-ում քաղաքացիներին զենքի տիրապետման համար բժշկական եզրակացություն տրամադրելու նպատակով բժշկական զննում",
                        "value": "15000"
                    },
                    {
                        "label": "Քաղվածք առողջ․ վերաբերյալ, տեղամասի 18-ից բարձր տարիքի անձանց համար",
                        "value": "5000"
                    },
                    {
                        "label": "Քաղվածք առողջության վերաբերյալ ըստ պայմանագրի",
                        "value": "4000"
                    },
                    {
                        "label": "Քաղվածք առողջության վերաբերյալ մեծ քանակության դեպքում",
                        "value": "3000"
                    },
                    {
                        "label": "Բուժզննում ըստ պայմանագրի",
                        "value": "3500"
                    },
                    {
                        "label": "Վարորդական վկայականի համար տեղեկանք",
                        "value": "15000"
                    },
                    {
                        "label": "Դատավորի պաշտոնում աշխ․ տեղեկանքի համար բուժզննում",
                        "value": "17000"
                    },
                    {
                        "label": "ՀՔԾ, ՔԿ ծառ․ աշխ․ տեղեկանքի համար բուժզննում",
                        "value": "15000"
                    },
                    {
                        "label": "Որդեգրման տեղեկանքի համար բուժզննում",
                        "value": "10000"
                    },
                    {
                        "label": "Հանրային ծառ․ աշխ․ տեղեկանքի համար բուժզննում",
                        "value": "22000"
                    },
                    {
                        "label": "Պրոֆ․ քննություն",
                        "value": "4000"
                    },
                    {
                        "label": "Թերապրտի խորհրդատվություն /փրկություն իմնադրամ/",
                        "value": "1000"
                    },
                    {
                        "label": "Դատախազի պաշտոնում աշխ․ տեղեկանքի համար բուժզննում",
                        "value": "17000"
                    },
                    {
                        "label": "Դատական կարգադրիչին ներկ․ առողջ․ տեղեկանք",
                        "value": "23000"
                    },
                    {
                        "label": "Հակակոռուպցիոն կոմիտեի պաշտոնում աշխ․ համար բուժզննում",
                        "value": "25000"
                    },
                    {
                        "label": "Արագացված տեղեկանքների տրամադրում 30-45ր-ի ընթացքում",
                        "value": "30000"
                    },
                    {
                        "label": "Քաղվածք Բուժ․ զնն․ Նորագավիթ",
                        "value": "8500"
                    },
                    {
                        "label": "Ձև 086/ու",
                        "value": "15000"
                    },
                    {
                        "label": "ԲՓՀ խորհրդատվություն",
                        "value": "5000"
                    }
                ]
            },
            {
                label: "Ցերեկային ստացիոնար",
                childs: [{
                    "label": "Ստացիոնար բուժում /1-10 օր/ յուրաք․ օրը",
                    "value": "5000"
                },
                {
                    "label": "Ստացիոնար բուժում /10 օրից ավել / յուրաք․ օրը",
                    "value": "4000"
                }
                ]
            },
        ]
    },
    {
        id: 14,
        label: 'Լ/Գ փաթեթներ',
        childs: [
            {
                label: "",
                childs: [{
                    "label": "Վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ",
                    "value": "10000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների, վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ",
                    "value": "10000"
                },
                {
                    "label": "Որովայնի խոռոչի օրգանների, վահանաձև գեղձի և կրծքագեղձի ՈՒՁՀ",
                    "value": "13000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների և վահանաձև գեղձի ՈՒՁՀ",
                    "value": "9000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների և կրծքագեղձի ՈՒՁՀ",
                    "value": "9000"
                },
                {
                    "label": "Որովայնի խոռոչի օրգանների և վահանաձև գեղձի ՈՒՁՀ",
                    "value": "9000"
                },
                {
                    "label": "Փոքր կոնքի օրգանների ՈՒՁՀ և PSA",
                    "value": "5000"
                },
                {
                    "label": "Վահանաձև գեղձի ՈՒՁՀ և խոլեսթերինային ֆրակցիաներ",
                    "value": "8000"
                },
                {
                    "label": "ԷՍԳ և խոլեսթերինային ֆրակցիաներ",
                    "value": "5000"
                },
                {
                    "label": "Արյան մակարդելություն, պրոթրոմբինային ինդեքս, խոլեսթերինային ֆրակցիաներ",
                    "value": "5000"
                },
                {
                    "label": "Արյան մակարդելություն, պրոթրոմբինային ինդեքս",
                    "value": "1500"
                },
                {
                    "label": "Մեզի ընդհանուր անալիզ, միզաթթվի և կրեատինինի որոշում",
                    "value": "2000"
                },
                {
                    "label": "Բիլիռուբինի ֆրակցիաներ, AST, ALT",
                    "value": "2000"
                },
                {
                    "label": "Հիմնային ֆոսֆատազա, GGT, HBsAg, HCV",
                    "value": "5000"
                },
                {
                    "label": "HBsAg, HCV",
                    "value": "4000"
                },
                {
                    "label": "HBsAg, HCV, AST, ALT",
                    "value": "5500"
                },
                {
                    "label": "Հիմնային ֆոսֆատազա, GGT, AST, ALT",
                    "value": "3000"
                },
                {
                    "label": "Ցիտոլոգիա, քսուկի անալիզ",
                    "value": "3500"
                },
                {
                    "label": "Քսուկ, ՊԱՊ քսուկ և կոլպոսկոպիա",
                    "value": "7000"
                },
                {
                    "label": "ՊԱՊ քսուկ և կոլպոսկոպիա",
                    "value": "6000"
                },
                {
                    "label": "Ցիտոլոգիա և կոլպոսկոպիա",
                    "value": "5500"
                },
                {
                    "label": "Քսուկ, խլամիդիա, ուրեոպլազմա",
                    "value": "6000"
                },
                {
                    "label": "ԷՍԳ, սպիրոգրաֆիա, պուլսօքսիմետրիա",
                    "value": "3000"
                },
                {
                    "label": "Ներհեշտոցային և փոքր կոնքի օրգանների ՈՒՁՀ",
                    "value": "10000"
                },
                {
                    "label": "Որովայնի, փ/կոնքի, վահանաձև գեղձի կամ որովայնի, փ/կոնքի, կրծքագեղձի ՈւՁՀ",
                    "value": "13000"
                },
                {
                    "label": "Որովայնի, փ/կոնքի, վահանաձև գեղձի, կրծքագեղձի ՈւՁՀ",
                    "value": "18000"
                },
                {
                    "label": "Որովայնի և Կրծքագեղձի ՈւՁՀ",
                    "value": "9000"
                }
                ]
            },
        ]
    },
    {
        id: 15,
        label: 'Պալիատիվ բուժօգնություն',
        childs: [
            {
                label: "",
                childs: [
                    {
                        "label": "Բժշկի առաջնակի զննում տանը",
                        "value": "6500"
                    },
                    {
                        "label": "Վարման պլանի կազմում",
                        "value": "5000"
                    },
                    {
                        "label": "Բժշկի կրկնակի այց տանը",
                        "value": "4000"
                    },
                    {
                        "label": "Բուժքրոջ այց տուն",
                        "value": "2500"
                    },
                    {
                        "label": "Հոգեբանի այց տուն",
                        "value": "6000"
                    },
                    {
                        "label": "Սոցաշխատողի այց տուն",
                        "value": "2500"
                    },
                    {
                        "label": "Ըստ անհրաժեշտության այլ մասնագետի տնային կանչ, խորհրդատվություն",
                        "value": "3500"
                    }
                ]
            },
        ]
    },
    {
        id: 16,
        label: 'Lաբորատոր  հետազոտություններ',
        childs: [
            {
                label: "Կենսաքիմիական հետազոտություն-Biochemical diagnostics",
                childs: [
                    {
                        "label": "Ընդհանուր սպիտակոց",
                        "value": "1000"
                    },
                    {
                        "label": "Ալբումին",
                        "value": "1000"
                    },
                    {
                        "label": "Միզաթթու",
                        "value": "1500"
                    },
                    {
                        "label": "Լակտատդեհիդրոգենազա",
                        "value": "1500"
                    },
                    {
                        "label": "Ամիլազա ընդհանուր",
                        "value": "1500"
                    },
                    {
                        "label": "α-ամիլազա պանկրեատիկ",
                        "value": "2000"
                    },
                    {
                        "label": "Դիաստազ",
                        "value": "2000"
                    },
                    {
                        "label": "Մագնեզիում",
                        "value": "1500"
                    },
                    {
                        "label": "Ցինկ",
                        "value": "7000"
                    },
                    {
                        "label": "Բարձր զգայնության C ռեակտիվ սպիտակո",
                        "value": "5000"
                    },
                    {
                        "label": "Երկաթ",
                        "value": "2000"
                    },
                    {
                        "label": "Ֆերրիտին",
                        "value": "10000"
                    },
                    {
                        "label": "Տրանսֆերին",
                        "value": "5000"
                    },
                    {
                        "label": "Ընդհանուր երկաթ կապող ունակություն",
                        "value": "3000"
                    },
                    {
                        "label": "Վիտամին B12",
                        "value": "6000"
                    },
                    {
                        "label": "Ֆոլիաթթու",
                        "value": "6000"
                    }


                ]
            },
            {
                label: "Կոագուլոգրամմա-Hemօstasis",
                childs: [
                    {
                        "label": "Կոագուլոգրամմա",
                        "value": "6000"
                    },
                    {
                        "label": "Ֆիբրինոգեն",
                        "value": "1500"
                    },
                    {
                        "label": "Անտիթրոմբին (ATIII)",
                        "value": "7000"
                    },
                    {
                        "label": "Պրոտեին C/S",
                        "value": "7000"
                    },
                    {
                        "label": "Դ-դիմեր",
                        "value": "8000"
                    },
                    {
                        "label": "Գայլախտային անտիկոագուլանտ(սկրինի",
                        "value": "8000"
                    }
                ]
            },
            {
                label: "Վահանագեղձի հիվանդություններ - Thyroid deseases",
                childs: [
                    {
                        "label": "Թիրոքսին ազատ",
                        "value": "6000"
                    },
                    {
                        "label": "Թիրեոտրոպ հորմոն",
                        "value": "6000"
                    },
                    {
                        "label": "Հակամարմիններ  թիրեոպերոքսիդազայի ն",
                        "value": "6500"
                    },
                    {
                        "label": "Հակամարմիններ TSH ռեցեպտորների նկա",
                        "value": "12000"
                    }
                ]
            },
            {
                label: "Շաքարային դիաբետ- Diabetes",
                childs: [
                    {
                        "label": "С-Պեպտիդ",
                        "value": "6000"
                    }
                ]
            },
            {
                label: "Հորմոններ -  Hormones",
                childs: [
                    {
                        "label": "Ֆոլիկուլխթանող հորմոն",
                        "value": "6000"
                    },
                    {
                        "label": "Լյուտեինացնող հորմոն",
                        "value": "6000"
                    },
                    {
                        "label": "Պրոլակտին",
                        "value": "6000"
                    },
                    {
                        "label": "Տեստոստերոն ընդհանուր",
                        "value": "6000"
                    },
                    {
                        "label": "Տեստոստերոն ազատ",
                        "value": "6000"
                    },
                    {
                        "label": "Դեհիդրոէպիանդրոստերոն սուլֆատ",
                        "value": "6000"
                    }
                ]
            },
            {
                label: "Հղիության վերահսկողություն - Monitoring of pregnance",
                childs: [
                    {
                        "label": "Խորիոնիկ հոնադոտրոպին",
                        "value": "6000"
                    },
                    {
                        "label": "Ազատ Էստրիոլ",
                        "value": "6000"
                    },
                    {
                        "label": "α-Ֆետոպրոտեին",
                        "value": "6000"
                    },
                    {
                        "label": "Անտիսպերմալ հակամարմիններ",
                        "value": "6000"
                    },
                    {
                        "label": "Հակաֆոսֆոլիպիդ. Հակամարմ. IgM",
                        "value": "6000"
                    },
                    {
                        "label": "Հակաֆոսֆոլիպիդ. Հակամարմ. IgG",
                        "value": "6000"
                    },
                    {
                        "label": "Հոմոցիստեին",
                        "value": "14000"
                    }
                ]
            },
            {
                label: "Կարդիոմարկերներ -  Cardiac markers",
                childs: [
                    {
                        "label": "Տրոպոնին T",
                        "value": "10000"
                    },
                    {
                        "label": "Կրետինկինազայի MB ֆրակցիա",
                        "value": "4000"
                    },
                    {
                        "label": "Միոգլոբին",
                        "value": "6000"
                    }
                ]
            },
            {
                label: "Ինֆեկցիաներ- Infection  diagnostics",
                childs: [
                    {
                        "label": "Տոքսոպլազմայի հակամարմիններ IgG",
                        "value": "6000"
                    },
                    {
                        "label": "Տոքսոպլազմայի հակամարմիններ IgM",
                        "value": "6000"
                    },
                    {
                        "label": "Ցիտոմեգալովիրուսի հակամարմիններ IgG",
                        "value": "6000"
                    },
                    {
                        "label": "Ցիտոմեգալովիրուսի հակամարմիններ IgM",
                        "value": "6000"
                    },
                    {
                        "label": "Կարմրախտի հակամարմիններ IgM",
                        "value": "6000"
                    },
                    {
                        "label": "Խլամիդիայի հակամարմիններ IgM",
                        "value": "6000"
                    },
                    {
                        "label": "Խլամիդիայի հակամարմիններ IgG",
                        "value": "6000"
                    },
                    {
                        "label": "Հերպեսի I/2 հակամարմիններ IgM",
                        "value": "6000/6000"
                    },
                    {
                        "label": "Հերպեսի I/2 հակամարմիններ IgG",
                        "value": "6000/6000"
                    },
                    {
                        "label": "ՄԻԱՎ հակամարմին/հակագեն կոմբ.",
                        "value": "6000"
                    },
                    {
                        "label": "Սիֆիլիս",
                        "value": "2000"
                    },
                    {
                        "label": "Բրուցելլյոզ (որակական թեսթ)",
                        "value": "2000"
                    }

                ]
            },
            {
                label: "Օնկոմարկերներ - Oncomarkers",
                childs: [
                    {
                        "label": "ՊՍԱ Ընդհանուր",
                        "value": "6000"
                    },
                    {
                        "label": "ՊՍԱ Ազատ",
                        "value": "6000"
                    },
                    {
                        "label": "Կարցինոէմբրիոնալ անտիգեն",
                        "value": "7000"
                    },
                    {
                        "label": "Անտիգեն 15-3",
                        "value": "7000"
                    },
                    {
                        "label": "Անտիգեն 125",
                        "value": "7000"
                    },
                    {
                        "label": "Ձվարանների էպիթելային բջիջների անտիգ",
                        "value": "10000"
                    },
                    {
                        "label": "Անտիգեն19-9",
                        "value": "7000"
                    },
                    {
                        "label": "Անտիգեն 242",
                        "value": "6000"
                    }
                ]
            },
            {
                label: "Սեռական օրգանների արտադրության հետազոտություն Urogenital sample and semen examination",
                childs: [
                    {
                        "label": "Սպերմոգրամմա",
                        "value": "6000"
                    },
                    {
                        "label": "Շագանակագեղձի հյութի հետազոտություն",
                        "value": "3000"
                    }
                ]
            },
            {
                label: "ՊՇՌ - PCR",
                childs: [
                    {
                        "label": "Պապիլոմա վիրուս 16, 18",
                        "value": "6000"
                    },
                    {
                        "label": "Տոքսոպլազմա",
                        "value": "6000"
                    },
                    {
                        "label": "Հերպես I-II",
                        "value": "6000"
                    },
                    {
                        "label": "Ցիտոմեգալովիրուս",
                        "value": "6000"
                    },
                    {
                        "label": "Քլամիդիա տրախոմատիս",
                        "value": "6000"
                    },
                    {
                        "label": "Գարդներելա վագինալիս",
                        "value": "6000"
                    },
                    {
                        "label": "Միկոպլազմա հոմինիս",
                        "value": "6000"
                    },
                    {
                        "label": "Միկոպլազմա գենիտալիում",
                        "value": "6000"
                    },
                    {
                        "label": "ՈՒրեապլազմա",
                        "value": "6000"
                    },
                    {
                        "label": "Սիֆիլիս",
                        "value": "6000"
                    },
                    {
                        "label": "Կանդիդա",
                        "value": "6000"
                    },
                    {
                        "label": "Թոքային և արտաթոքային տուբերկուլյոզ",
                        "value": "6000"
                    },
                    {
                        "label": "Էպշտեին-Բարի վիրուս",
                        "value": "8000"
                    },
                    {
                        "label": "Էպշտեին-Բարի վիրուսի ԴՆԹ քանակակա",
                        "value": "20000"
                    },
                    {
                        "label": "Բրուցելլա",
                        "value": "6000"
                    },
                    {
                        "label": "Հելիկոբակտեր պիլորի",
                        "value": "6000"
                    }
                ]
            },
            {
                label: "Էքսպրես մանրէաբանական հետազոտություններ Հետազոտությունը իրականացվում է Vitek 2 compact անալիզատորով",
                childs: [
                    {
                        "label": "Մեզի մանրէաբանական հետազոտություն",
                        "value": "15000"
                    },
                    {
                        "label": "Կղանքի մանրէաբանական հետազոտություն",
                        "value": "15000"
                    },
                    {
                        "label": "Խորխի մանրէաբանական հետազոտություն",
                        "value": "15000"
                    },
                    {
                        "label": "Քսուքների (քիթ, կոկորդ, ականջ, աչք, լնդերի, լեզվի, վերքային մակերես, թարախի) մանրէաբանական հետազոտություն",
                        "value": "18000"
                    },
                    {
                        "label": "Արյան ստերիլություն (աէրոբ մանրէներ, սնկ)",
                        "value": "15000"
                    },
                    {
                        "label": "Արյան ստերիլություն (օբլիգատ անաէրոբ մ)",
                        "value": "15000"
                    },
                    {
                        "label": "Կղանքի դիսբիոզի հետազոտություն",
                        "value": "15000"
                    },
                    {
                        "label": "Կղանքի, մեզի մանրէաբանական հետազոտություն Yersinia",
                        "value": "15000"
                    }
                ]
            },
            {
                label: "Դասական եղանակով մանրէաբանական հետազոտություններ",
                childs: [
                    {
                        "label": "Մեզի մանրէաբանական հետազոտություն",
                        "value": "8000"
                    },
                    {
                        "label": "Կղանքի մանրէաբանական հետազոտություն",
                        "value": "8000"
                    },
                    {
                        "label": "Ուրոգենիտալ քսուքների, էակուլյատի մանրէաբանական հետազոտություն",
                        "value": "8000"
                    },
                    {
                        "label": "Խորխի մանրէաբանական հետազոտություն",
                        "value": "8000"
                    },
                    {
                        "label": "Քսուքների (քիթ, կոկորդ, ականջ, աչք, լնդերի, լեզվի, վերքային մակերես, թարախի) մանրէաբանական հետազոտություն",
                        "value": "8000"
                    },
                    {
                        "label": "Արյան ստերիլություն (աէրոբ մանրէներ, սնկեր)",
                        "value": "8000"
                    },
                    {
                        "label": "Կղանքի դիսբիոզի հետազոտություն",
                        "value": "8000"
                    },
                    {
                        "label": "Streptococcus agalactiae (B խմբի բետտա հեմոլիտիկ Ստրեպտոկոկ) հղիների սկրինինգ",
                        "value": "4000"
                    },
                    {
                        "label": "Helicobacter pylori stool Ag կղանքի հետազոտություն",
                        "value": "6000"
                    },
                    {
                        "label": "Մազի, եղունգի, մաշկի քերուկի մանրէաբանական հետազոտություն սնկերի հայտնաբերման վերաբերյալ",
                        "value": "5000"
                    },
                    {
                        "label": "Մազի, եղունգի, մաշկի քերուկի մանրէաբանական հետազոտություն սնկերի հայտնաբերման վերաբերյալ (մանրադիտակային)",
                        "value": "3000"
                    }
                ]
            }
        ]
    },
]
export default priceListData;